/* You can add global styles to this file, and also import other style files */
// @import "styles/_lib";
// @import "styles/_nav";
// @import "styles/_buttons";
// @import "styles/_cards";
// @import "styles/_base";
// @import "styles/_styles";

@navBackgroundColor: #15335A;
@navHoverColor: #205191;
@navHoverBackgroundColor: #fff;
@navDarkBackgroundColor: #000034;
@navDarkColor: #15335A;
@mainHeaderColor: #abb8c3;
@mainHeaderTextColor: #15335A;

// TODO CCC: figure out better way to do these styles
tr.archived {
    background-color: rgb(255, 192, 203) !important;
}
tr.archived > td {
    background-color: rgb(255, 192, 203) !important;
}

.app-root-wrapper {
    background-color: #fff;
    min-height: 100vh;
}

.content-wrapper {
    padding-top: 100px;
}

.posts-item {
    background-color: transparent !important;
    border-color: transparent !important;
    td {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .miles-card {
        box-shadow: none;

        &:hover {
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.18824);
        }
    }
}

table 
{
    table-layout:fixed;
    width:100%;
}

.skin-blue .main-sidebar {
    background-color: @navBackgroundColor;
}

.skin-blue .sidebar a {
    color: #fff;
}

.skin-blue .sidebar-menu > li:hover > app-nav-menu-item > a,
.skin-blue .sidebar-menu > li.active > app-nav-menu-item > a,
.skin-blue .sidebar-menu > li:hover > app-nav-menu-item > a,
.skin-blue .sidebar-menu > li.active > app-nav-menu-item > a {
    color: @navHoverBackgroundColor;
    background: @navHoverColor;
    border-left-color: #52636d;
}

.skin-blue .sidebar-menu > li > .treeview-menu,
.skin-blue .sidebar-menu > li > .treeview-menu {
    margin: 0 1px;
    background: @navDarkBackgroundColor;
}

.skin-blue .sidebar-menu > li.header {
    background: @navDarkBackgroundColor;
}

.skin-blue .main-header > .logo:hover {
    background-color: @mainHeaderColor;
}

.skin-blue .main-header > .logo {
    background-color: @mainHeaderColor;
    color: @mainHeaderTextColor;
    border-bottom: 0 solid transparent;
}

.skin-blue .main-header .logo {
    background-color: @mainHeaderColor;
    color: @mainHeaderTextColor;
    border-bottom: 0 solid transparent;
}

.skin-blue .main-header .navbar .sidebar-toggle:hover {
    background-color: @mainHeaderTextColor;
}

.skin-blue .main-header > .navbar {
    background-color: @mainHeaderColor;
}

.sidebar-menu .treeview-menu>li>app-nav-menu-item>a {
    padding: 5px 5px 5px 15px;
    display: block;
    font-size: 12px;
}

.skin-blue .main-header .navbar .nav>li>a {
    color: #15335A;
}

.skin-blue .main-header li.user-header {
    background-color: #15335A;
}

.btn.btn-fab-lg, .btn.btn-fab-md, .btn.btn-fab-spin-lg {
    background-color: #15335A;
    border-color: #15335A;
}

.btn-primary, .btn-primary:active, .btn-primary:focus, .btn-primary:visited {
    background-color: #15335A;
    border-color: #15335A;
}

.btn-success, .btn-success:active, .btn-success:focus, .btn-success:visited {
    background-color: #15335A;
    border-color: #15335A;
}

.btn-primary-inverse.active, .btn-primary-inverse.active:hover, .btn-primary-inverse.focus.active, .btn-primary-inverse.focus.active:hover, .btn-primary-inverse:active:focus, .btn-primary-inverse:active:hover, .btn-primary-inverse:hover:not([disabled]) {
    background-color: #15335A;
    border-color: #000;
    color: #fff;
    outline: 0;
}

.main-header.hidden-sm.hidden-xs > nav > div > header-current-user-details > ul > li > ul > li > button {
    background-color: red;
    border-color: red;
}

.navbar-nav.nav-header-button {
    color: #15335A;
    cursor: pointer;
    max-height: 50px;
    min-width: 30px;
    text-align: center;
}

.navbar-nav.submit-help {
    color: #15335A;
    max-height: 50px;
    min-width: 30px;
    text-align: center;
}

body > app-root > div:nth-child(1) > div > div > ng-component > div > div > app-nav > app-nav-header > header.main-header.hidden-sm.hidden-xs > nav > div > header-shortcut-help > div {
    color: #15335A;
}
